'use strict';

import $ from 'jquery';
import 'jquery-mask-plugin';

$(function() {
    $(document).on('keyup', '.uppercase', function() {
        $(this).val($(this).val().toUpperCase())
    })

    $(document).on('keyup', '.ucfirst', function() {
        $(this).val($(this).val().charAt(0).toUpperCase() + $(this).val().slice(1));
    })

    $('.format-telephone').mask('00 00 00 00 00');
    $('.format-montant').mask('### ### ### ### ### ###', {reverse: true, translation:  {'#': {pattern: /[0-9\-]/, recursive: true, optional: true}}});

    $('form').submit(function() {
        $('.format-telephone').unmask();
        $('.format-montant').unmask();
    })
})

'use strict';

class AddressGoogleMapApp {
    loadAutocompleteGoogleMapField() {
        $('.adresse-saisie-googlemap').each(function() {
            var elementId = $(this).attr('id');
            var elementPrefix = elementId.replace('_adresseSaisie', '');
            var element = document.getElementById(elementId);
            var datas = new Array;

            $(document).on('keyup', $(this), function() {
                datas['street_number']                  = '';
                datas['route']                          = '';
                datas['postal_code']                    = '';
                datas['locality']                       = '';
                datas['administrative_area_level_1']    = '';
                datas['administrative_area_level_2']    = '';
                datas['country']                        = '';
                datas['longitude']                      = '';
                datas['latitude']                       = '';

                $('#' + elementPrefix + '_numero').val('');
                $('#' + elementPrefix + '_route').val('');
                $('#' + elementPrefix + '_codePostal').val('');
                $('#' + elementPrefix + '_villeText').val('');
                $('#' + elementPrefix + '_departementText').val('');
                $('#' + elementPrefix + '_regionText').val('');
                $('#' + elementPrefix + '_paysText').val('');
                $('#' + elementPrefix + '_longitude').val('');
                $('#' + elementPrefix + '_latitude').val('');

                if (element) {
                    var autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] });

                    google.maps.event.addListener(autocomplete, 'place_changed', function() {
                        var place = this.getPlace();

                        for (var i in place.address_components) {
                            var component = place.address_components[i];

                            datas[component.types[0]] = component.long_name
                        }

                        $('#' + elementPrefix + '_numero').val(datas['street_number']);
                        $('#' + elementPrefix + '_route').val(datas['route']);
                        $('#' + elementPrefix + '_codePostal').val(datas['postal_code']);
                        $('#' + elementPrefix + '_villeText').val(datas['locality']);
                        $('#' + elementPrefix + '_departementText').val(datas['administrative_area_level_2']);
                        $('#' + elementPrefix + '_regionText').val(datas['administrative_area_level_1']);
                        $('#' + elementPrefix + '_paysText').val(datas['country']);
                        $('#' + elementPrefix + '_longitude').val(place.geometry.location.lat());
                        $('#' + elementPrefix + '_latitude').val(place.geometry.location.lng());
                    });

                    if ($('#' + elementPrefix + '_villeText').val() == '') {
                        var geocoder = new google.maps.Geocoder();
                        var address = $('#' + elementId).val();

                        geocoder.geocode({'address': address}, function(results, status) {
                            if (status === 'OK') {
                                for (var i in results[0].address_components) {
                                    var component = results[0].address_components[i];

                                    datas[component.types[0]] = component.long_name
                                }

                                $('#' + elementPrefix + '_numero').val(datas['street_number']);
                                $('#' + elementPrefix + '_route').val(datas['route']);
                                $('#' + elementPrefix + '_codePostal').val(datas['postal_code']);
                                $('#' + elementPrefix + '_villeText').val(datas['locality']);
                                $('#' + elementPrefix + '_departementText').val(datas['administrative_area_level_2']);
                                $('#' + elementPrefix + '_regionText').val(datas['administrative_area_level_1']);
                                $('#' + elementPrefix + '_paysText').val(datas['country']);
                                $('#' + elementPrefix + '_longitude').val(results[0].geometry.location.lat());
                                $('#' + elementPrefix + '_latitude').val(results[0].geometry.location.lat());
                            }
                        });
                    }
                }
            })
        })
    }
}

var exportClass = new AddressGoogleMapApp();

export default exportClass;